

a {
  text-decoration: none;
  display: inline-block;
}

.btn {
  height: 50px;
  line-height: 50px;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.text {
  padding: 0 50px;
  visibility: hidden;

}

.flip-front, 
.flip-back {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform-style: flat;
    transform-style: flat;
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
}

.flip-front {
    color: #5b2aec;
    border: 2px solid #5b2aec;
    background-color: transparent;
    -webkit-transform: rotateX(0deg) translateZ(25px);
    -ms-transform: rotateX(0deg) translateZ(25px);
    -o-transform: rotateX(0deg) translateZ(25px);
    transform: rotateX(0deg) translateZ(25px);
}

.flip-back {
  color: #fff;
  background-color: #5b2aec;
  border: 2px #5b2aec;
  border-style: dotted double dashed double;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: rotateX(90deg) translateZ(25px);
  -ms-transform: rotateX(90deg) translateZ(25px);
  -o-transform: rotateX(90deg) translateZ(25px);
  transform: rotateX(90deg) translateZ(25px);
}

.btn:hover .flip-front {
    -webkit-transform: rotateX(-90deg) translateZ(25px);
    -ms-transform: rotateX(-90deg) translateZ(25px);
    -o-transform: rotateX(-90deg) translateZ(25px);
    transform: rotateX(-90deg) translateZ(25px);
}

.btn:hover .flip-back {
    -webkit-transform: rotateX(0deg) translateZ(25px);
    -ms-transform: rotateX(0deg) translateZ(25px);
    -o-transform: rotateX(0deg) translateZ(25px);
    transform: rotateX(0deg) translateZ(25px);
}