@keyframes shine {
  to {
    background-position: 200% center;
  }
}


@keyframes blob {

  0%,
  100% {
    border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
  }

  5% {
    border-radius: 30% 70% 70% 30% / 70% 70% 30% 30%;
  }

  10% {
    border-radius: 35% 65% 65% 35% / 65% 65% 35% 35%;
  }

  15% {
    border-radius: 40% 60% 60% 40% / 60% 60% 40% 40%;
  }

  20% {
    border-radius: 45% 55% 55% 45% / 55% 55% 45% 45%;
  }

  25% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }

  30% {
    border-radius: 55% 45% 45% 55% / 45% 45% 55% 55%;
  }

  35% {
    border-radius: 60% 40% 40% 60% / 40% 40% 60% 60%;
  }

  40% {
    border-radius: 65% 35% 35% 65% / 35% 35% 65% 65%;
  }

  45% {
    border-radius: 70% 30% 30% 70% / 30% 30% 70% 70%;
  }

  50% {
    border-radius: 75% 25% 25% 75% / 25% 25% 75% 75%;
  }

  55% {
    border-radius: 70% 30% 30% 70% / 30% 30% 70% 70%;
  }

  60% {
    border-radius: 65% 35% 35% 65% / 35% 35% 65% 65%;
  }

  65% {
    border-radius: 60% 40% 40% 60% / 40% 40% 60% 60%;
  }

  70% {
    border-radius: 55% 45% 45% 55% / 45% 45% 55% 55%;
  }

  75% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }

  80% {
    border-radius: 45% 55% 55% 45% / 55% 55% 45% 45%;
  }

  85% {
    border-radius: 40% 60% 60% 40% / 60% 60% 40% 40%;
  }

  90% {
    border-radius: 35% 65% 65% 35% / 65% 65% 35% 35%;
  }

  95% {
    border-radius: 30% 70% 70% 30% / 70% 70% 30% 30%;
  }
}

@keyframes shadowAnim {
  0% {
    box-shadow:
      0 0 calc(1 * 6em) calc(1 * 1.5em) hsl(260 97% 61% / 0.75),
      0 0.1em 0 0 hsl(260 calc(1 * 97%) calc((1 * 50%) + 30%)) inset,
      0 -0.05em 0 0 hsl(260 calc(1 * 97%) calc(1 * 60%)) inset;
  }

  100% {
    box-shadow:
      0 0 calc(1 * 2em) calc(1 * 2em) #7b3bfcbf,
      0 0.1em 0 0 hsl(260 calc(1 * 97%) calc((1 * 55%) + 30%)) inset,
      0 -0.1em 0 0 hsl(260 calc(1 * 97%) calc(1 * 65%)) inset;
  }
}