/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;500;700&display=swap');

/* import css  */

@import url("./css/index.css");


/* general styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* animation */
.animation {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    transition: 0.5s;
}

.animation.active {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}




