.circle {
    position: relative;
  }
  
  .orbit {
    position: absolute;
    width: 100%;
    height: 100%;
    border-top: 5px solid rgba(255, 255, 255, 0.669);
    border-radius: 50%;
    animation: spin 8s linear infinite;
  }
  .orbit_two {
    top: 2px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-top: 4px solid rgba(255, 255, 255, 0.691);
    border-radius: 50%;
    animation: spin 4s linear 3s infinite;
  }
  
  .orbit:nth-child(2) {
    animation-duration: 4s;
    border-color: rgba(255, 255, 255, 0.6);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  