body {
  background-color: #010824;
  color: #eee;
  font-size: 15px;
  min-height: 100vh;

  &::before {
    position: fixed;
    width: 400px;
    height: 200px;
    content: '';
    background-image: linear-gradient(to right, #23d037, #160795);
    z-index: -1;
    top: -200px;
    left: calc(50% - 200px);
    border-radius: 50% 50% 0% 0%;
    filter: blur(250px);
  }
}

section {
  width: 1300px;
  max-width: 100%;
  margin: auto;
  top: 0;
}



.text-hover:hover {
  color: #6330fe;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
.rel {
  position: relative;
  z-index: 2;

}

.generall__border {
  border: 3px solid transparent;
  border-radius: 10px;
  transition: border 1s ease, border-radius 1s ease;
}

.generall__border:hover {
  border: 3px #6230fe;
  border-style: dotted double dashed double;
  animation: border-animation 5s infinite linear;
  border-radius: 20px;
  border-spacing: 100;
}

@media only screen and (max-width: 992px) {
  .generall__border {
    border: 3px #6230fe;
    border-style: dotted double dashed double;
    animation: border-animation 5s infinite linear;
    border-radius: 20px;
    border-spacing: 100;
  }
}

@keyframes border-animation {
  0% {
    border-style: dotted double dashed double dashed;
    border-radius: 10px;
  }

  25% {
    border-style: double dotted double dashed double;
    border-radius: 20px;
  }

  50% {
    border-style: dotted double dashed double dashed;
    border-radius: 30px;
  }

  75% {
    border-style: double dotted double dashed double;
    border-radius: 20px;
  }

  100% {
    border-style: dotted double dashed double dashed;
    border-radius: 10px;
  }
}