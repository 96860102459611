/* Custom Scrollbar Styles */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #0a0a0a;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #2193b0, #6dd5ed);
    border-radius: 10px;
    border: 2px solid #0a0a0a;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #6dd5ed, #2193b0);
    cursor: pointer;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Firefox scrollbar styles */
* {
    scrollbar-width: thin;
    scrollbar-color: #1f1d52 #0a0a0a;
}